import type { AspidaClient, BasicHeaders } from 'aspida';
import { dataToURLString } from 'aspida';
import type { Methods as Methods_1a90oa1 } from './web/auth/azure_sessions';
import type { Methods as Methods_1bkw04v } from './web/logout';
import type { Methods as Methods_sc43or } from './web/me';
import type { Methods as Methods_axar9f } from './web/me/points';
import type { Methods as Methods_1k1w4e2 } from './web/tenants/_tenant_id@string/admin/csv/cards';
import type { Methods as Methods_11k4amw } from './web/tenants/_tenant_id@string/admin/csv/employees';
import type { Methods as Methods_18p90v1 } from './web/tenants/_tenant_id@string/admin/csv/paging/employees';
import type { Methods as Methods_10pxkoz } from './web/tenants/_tenant_id@string/admin/employees';
import type { Methods as Methods_nr0e8c } from './web/tenants/_tenant_id@string/admin/employees/_employee_id@string';
import type { Methods as Methods_1ep2f60 } from './web/tenants/_tenant_id@string/admin/team_hash_tags';
import type { Methods as Methods_1wz2ium } from './web/tenants/_tenant_id@string/admin/team_hash_tags/_team_hash_tag_id@string';
import type { Methods as Methods_4gjfp2 } from './web/tenants/_tenant_id@string/admin/tenant';
import type { Methods as Methods_t11qph } from './web/tenants/_tenant_id@string/admin/terms';
import type { Methods as Methods_17yfkzc } from './web/tenants/_tenant_id@string/admin/terms/_term_id@string';
import type { Methods as Methods_jgvknx } from './web/tenants/_tenant_id@string/cards';
import type { Methods as Methods_h6q8fw } from './web/tenants/_tenant_id@string/cards/_card_id@string';
import type { Methods as Methods_110f06c } from './web/tenants/_tenant_id@string/cards/_card_id@string/claps';
import type { Methods as Methods_4o42tl } from './web/tenants/_tenant_id@string/cards/many';
import type { Methods as Methods_1qrfwfm } from './web/tenants/_tenant_id@string/cards/tagged';
import type { Methods as Methods_z4epn } from './web/tenants/_tenant_id@string/employees';
import type { Methods as Methods_1a7zqsk } from './web/tenants/_tenant_id@string/employees/_employee_id@string';
import type { Methods as Methods_f6rjq0 } from './web/tenants/_tenant_id@string/employees/_employee_id@string/cards/clapped';
import type { Methods as Methods_2tcmvu } from './web/tenants/_tenant_id@string/employees/_employee_id@string/cards/received';
import type { Methods as Methods_16fgjxj } from './web/tenants/_tenant_id@string/employees/_employee_id@string/cards/sent';
import type { Methods as Methods_10e8p1n } from './web/tenants/_tenant_id@string/employees/_employee_id@string/slack_notifications_setting';
import type { Methods as Methods_141urs0 } from './web/tenants/_tenant_id@string/team_hash_tags';

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'http://localhost:3000/api/v1' : baseURL).replace(/\/$/, '');
  const PATH0 = '/web/auth/azure_sessions';
  const PATH1 = '/web/logout';
  const PATH2 = '/web/me';
  const PATH3 = '/web/me/points';
  const PATH4 = '/web/tenants';
  const PATH5 = '/admin/csv/cards';
  const PATH6 = '/admin/csv/employees';
  const PATH7 = '/admin/csv/paging/employees';
  const PATH8 = '/admin/employees';
  const PATH9 = '/admin/team_hash_tags';
  const PATH10 = '/admin/tenant';
  const PATH11 = '/admin/terms';
  const PATH12 = '/cards';
  const PATH13 = '/claps';
  const PATH14 = '/cards/many';
  const PATH15 = '/cards/tagged';
  const PATH16 = '/employees';
  const PATH17 = '/cards/clapped';
  const PATH18 = '/cards/received';
  const PATH19 = '/cards/sent';
  const PATH20 = '/slack_notifications_setting';
  const PATH21 = '/team_hash_tags';
  const GET = 'GET';
  const POST = 'POST';
  const DELETE = 'DELETE';
  const PATCH = 'PATCH';

  return {
    web: {
      auth: {
        azure_sessions: {
          /**
           * azureでログイン
           * @returns ok
           */
          post: (option: { body: Methods_1a90oa1['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1a90oa1['post']['resBody'], BasicHeaders, Methods_1a90oa1['post']['status']>(prefix, PATH0, POST, option).json(),
          /**
           * azureでログイン
           * @returns ok
           */
          $post: (option: { body: Methods_1a90oa1['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_1a90oa1['post']['resBody'], BasicHeaders, Methods_1a90oa1['post']['status']>(prefix, PATH0, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH0}`,
        },
      },
      logout: {
        /**
         * ログアウトを行うAPI
         * @returns OK
         */
        delete: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1bkw04v['delete']['resBody'], BasicHeaders, Methods_1bkw04v['delete']['status']>(prefix, PATH1, DELETE, option).json(),
        /**
         * ログアウトを行うAPI
         * @returns OK
         */
        $delete: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_1bkw04v['delete']['resBody'], BasicHeaders, Methods_1bkw04v['delete']['status']>(prefix, PATH1, DELETE, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH1}`,
      },
      me: {
        points: {
          /**
           * ログイン中の従業員のポイントを取得するAPI
           * @returns OK
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_axar9f['get']['resBody'], BasicHeaders, Methods_axar9f['get']['status']>(prefix, PATH3, GET, option).json(),
          /**
           * ログイン中の従業員のポイントを取得するAPI
           * @returns OK
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_axar9f['get']['resBody'], BasicHeaders, Methods_axar9f['get']['status']>(prefix, PATH3, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH3}`,
        },
        /**
         * ログイン状態を取得するAPI
         * @returns OK
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_sc43or['get']['resBody'], BasicHeaders, Methods_sc43or['get']['status']>(prefix, PATH2, GET, option).json(),
        /**
         * ログイン状態を取得するAPI
         * @returns OK
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_sc43or['get']['resBody'], BasicHeaders, Methods_sc43or['get']['status']>(prefix, PATH2, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH2}`,
      },
      tenants: {
        _tenant_id: (val2: string) => {
          const prefix2 = `${PATH4}/${val2}`;

          return {
            admin: {
              csv: {
                cards: {
                  /**
                   * 投稿レコードの日付指定CSVダウンロードAPI
                   */
                  get: (option: { query: Methods_1k1w4e2['get']['query'], config?: T | undefined }) =>
                    fetch<Methods_1k1w4e2['get']['resBody'], BasicHeaders, Methods_1k1w4e2['get']['status']>(prefix, `${prefix2}${PATH5}`, GET, option).json(),
                  /**
                   * 投稿レコードの日付指定CSVダウンロードAPI
                   */
                  $get: (option: { query: Methods_1k1w4e2['get']['query'], config?: T | undefined }) =>
                    fetch<Methods_1k1w4e2['get']['resBody'], BasicHeaders, Methods_1k1w4e2['get']['status']>(prefix, `${prefix2}${PATH5}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get' | undefined; query: Methods_1k1w4e2['get']['query'] } | undefined) =>
                    `${prefix}${prefix2}${PATH5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
                },
                employees: {
                  /**
                   * 従業員別レコードの日付指定CSVダウンロードAPI
                   */
                  get: (option: { query: Methods_11k4amw['get']['query'], config?: T | undefined }) =>
                    fetch<Methods_11k4amw['get']['resBody'], BasicHeaders, Methods_11k4amw['get']['status']>(prefix, `${prefix2}${PATH6}`, GET, option).json(),
                  /**
                   * 従業員別レコードの日付指定CSVダウンロードAPI
                   */
                  $get: (option: { query: Methods_11k4amw['get']['query'], config?: T | undefined }) =>
                    fetch<Methods_11k4amw['get']['resBody'], BasicHeaders, Methods_11k4amw['get']['status']>(prefix, `${prefix2}${PATH6}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get' | undefined; query: Methods_11k4amw['get']['query'] } | undefined) =>
                    `${prefix}${prefix2}${PATH6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
                },
                paging: {
                  employees: {
                    /**
                     * 従業員別レコードの日付指定CSVダウンロードAPI
                     */
                    get: (option: { query: Methods_18p90v1['get']['query'], config?: T | undefined }) =>
                      fetch<Methods_18p90v1['get']['resBody'], BasicHeaders, Methods_18p90v1['get']['status']>(prefix, `${prefix2}${PATH7}`, GET, option).json(),
                    /**
                     * 従業員別レコードの日付指定CSVダウンロードAPI
                     */
                    $get: (option: { query: Methods_18p90v1['get']['query'], config?: T | undefined }) =>
                      fetch<Methods_18p90v1['get']['resBody'], BasicHeaders, Methods_18p90v1['get']['status']>(prefix, `${prefix2}${PATH7}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get' | undefined; query: Methods_18p90v1['get']['query'] } | undefined) =>
                      `${prefix}${prefix2}${PATH7}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
                  },
                },
              },
              employees: {
                _employee_id: (val5: string) => {
                  const prefix5 = `${prefix2}${PATH8}/${val5}`;

                  return {
                    /**
                     * 従業員個別取得API
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<Methods_nr0e8c['get']['resBody'], BasicHeaders, Methods_nr0e8c['get']['status']>(prefix, prefix5, GET, option).json(),
                    /**
                     * 従業員個別取得API
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<Methods_nr0e8c['get']['resBody'], BasicHeaders, Methods_nr0e8c['get']['status']>(prefix, prefix5, GET, option).json().then(r => r.body),
                    /**
                     * 従業員情報更新API
                     */
                    patch: (option: { body: Methods_nr0e8c['patch']['reqBody'], config?: T | undefined }) =>
                      fetch<Methods_nr0e8c['patch']['resBody'], BasicHeaders, Methods_nr0e8c['patch']['status']>(prefix, prefix5, PATCH, option).json(),
                    /**
                     * 従業員情報更新API
                     */
                    $patch: (option: { body: Methods_nr0e8c['patch']['reqBody'], config?: T | undefined }) =>
                      fetch<Methods_nr0e8c['patch']['resBody'], BasicHeaders, Methods_nr0e8c['patch']['status']>(prefix, prefix5, PATCH, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix5}`,
                  };
                },
                /**
                 * 従業員一覧取得API
                 */
                get: (option: { query: Methods_10pxkoz['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_10pxkoz['get']['resBody'], BasicHeaders, Methods_10pxkoz['get']['status']>(prefix, `${prefix2}${PATH8}`, GET, option).json(),
                /**
                 * 従業員一覧取得API
                 */
                $get: (option: { query: Methods_10pxkoz['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_10pxkoz['get']['resBody'], BasicHeaders, Methods_10pxkoz['get']['status']>(prefix, `${prefix2}${PATH8}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_10pxkoz['get']['query'] } | undefined) =>
                  `${prefix}${prefix2}${PATH8}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              },
              team_hash_tags: {
                _team_hash_tag_id: (val5: string) => {
                  const prefix5 = `${prefix2}${PATH9}/${val5}`;

                  return {
                    /**
                     * チームハッシュタグ個別取得API
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<Methods_1wz2ium['get']['resBody'], BasicHeaders, Methods_1wz2ium['get']['status']>(prefix, prefix5, GET, option).json(),
                    /**
                     * チームハッシュタグ個別取得API
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<Methods_1wz2ium['get']['resBody'], BasicHeaders, Methods_1wz2ium['get']['status']>(prefix, prefix5, GET, option).json().then(r => r.body),
                    /**
                     * チームハッシュタグの情報更新API
                     */
                    patch: (option: { body: Methods_1wz2ium['patch']['reqBody'], config?: T | undefined }) =>
                      fetch<Methods_1wz2ium['patch']['resBody'], BasicHeaders, Methods_1wz2ium['patch']['status']>(prefix, prefix5, PATCH, option).json(),
                    /**
                     * チームハッシュタグの情報更新API
                     */
                    $patch: (option: { body: Methods_1wz2ium['patch']['reqBody'], config?: T | undefined }) =>
                      fetch<Methods_1wz2ium['patch']['resBody'], BasicHeaders, Methods_1wz2ium['patch']['status']>(prefix, prefix5, PATCH, option).json().then(r => r.body),
                    /**
                     * チームハッシュタグの削除API
                     */
                    delete: (option?: { config?: T | undefined } | undefined) =>
                      fetch<void, BasicHeaders, Methods_1wz2ium['delete']['status']>(prefix, prefix5, DELETE, option).send(),
                    /**
                     * チームハッシュタグの削除API
                     */
                    $delete: (option?: { config?: T | undefined } | undefined) =>
                      fetch<void, BasicHeaders, Methods_1wz2ium['delete']['status']>(prefix, prefix5, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix5}`,
                  };
                },
                /**
                 * チームハッシュタグ一覧取得API (管理者用)
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1ep2f60['get']['resBody'], BasicHeaders, Methods_1ep2f60['get']['status']>(prefix, `${prefix2}${PATH9}`, GET, option).json(),
                /**
                 * チームハッシュタグ一覧取得API (管理者用)
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1ep2f60['get']['resBody'], BasicHeaders, Methods_1ep2f60['get']['status']>(prefix, `${prefix2}${PATH9}`, GET, option).json().then(r => r.body),
                /**
                 * チームハッシュタグ作成API
                 */
                post: (option: { body: Methods_1ep2f60['post']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_1ep2f60['post']['resBody'], BasicHeaders, Methods_1ep2f60['post']['status']>(prefix, `${prefix2}${PATH9}`, POST, option).json(),
                /**
                 * チームハッシュタグ作成API
                 */
                $post: (option: { body: Methods_1ep2f60['post']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_1ep2f60['post']['resBody'], BasicHeaders, Methods_1ep2f60['post']['status']>(prefix, `${prefix2}${PATH9}`, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix2}${PATH9}`,
              },
              tenant: {
                /**
                 * テナント情報の取得api
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_4gjfp2['get']['resBody'], BasicHeaders, Methods_4gjfp2['get']['status']>(prefix, `${prefix2}${PATH10}`, GET, option).json(),
                /**
                 * テナント情報の取得api
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_4gjfp2['get']['resBody'], BasicHeaders, Methods_4gjfp2['get']['status']>(prefix, `${prefix2}${PATH10}`, GET, option).json().then(r => r.body),
                /**
                 * テナント情報の更新api
                 */
                patch: (option: { body: Methods_4gjfp2['patch']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_4gjfp2['patch']['resBody'], BasicHeaders, Methods_4gjfp2['patch']['status']>(prefix, `${prefix2}${PATH10}`, PATCH, option).json(),
                /**
                 * テナント情報の更新api
                 */
                $patch: (option: { body: Methods_4gjfp2['patch']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_4gjfp2['patch']['resBody'], BasicHeaders, Methods_4gjfp2['patch']['status']>(prefix, `${prefix2}${PATH10}`, PATCH, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix2}${PATH10}`,
              },
              terms: {
                _term_id: (val5: string) => {
                  const prefix5 = `${prefix2}${PATH11}/${val5}`;

                  return {
                    /**
                     * term個別取得API
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<Methods_17yfkzc['get']['resBody'], BasicHeaders, Methods_17yfkzc['get']['status']>(prefix, prefix5, GET, option).json(),
                    /**
                     * term個別取得API
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<Methods_17yfkzc['get']['resBody'], BasicHeaders, Methods_17yfkzc['get']['status']>(prefix, prefix5, GET, option).json().then(r => r.body),
                    /**
                     * term更新API
                     */
                    patch: (option: { body: Methods_17yfkzc['patch']['reqBody'], config?: T | undefined }) =>
                      fetch<Methods_17yfkzc['patch']['resBody'], BasicHeaders, Methods_17yfkzc['patch']['status']>(prefix, prefix5, PATCH, option).json(),
                    /**
                     * term更新API
                     */
                    $patch: (option: { body: Methods_17yfkzc['patch']['reqBody'], config?: T | undefined }) =>
                      fetch<Methods_17yfkzc['patch']['resBody'], BasicHeaders, Methods_17yfkzc['patch']['status']>(prefix, prefix5, PATCH, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix5}`,
                  };
                },
                /**
                 * term一覧取得API
                 */
                get: (option?: { query?: Methods_t11qph['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                  fetch<Methods_t11qph['get']['resBody'], BasicHeaders, Methods_t11qph['get']['status']>(prefix, `${prefix2}${PATH11}`, GET, option).json(),
                /**
                 * term一覧取得API
                 */
                $get: (option?: { query?: Methods_t11qph['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                  fetch<Methods_t11qph['get']['resBody'], BasicHeaders, Methods_t11qph['get']['status']>(prefix, `${prefix2}${PATH11}`, GET, option).json().then(r => r.body),
                /**
                 * term作成API
                 */
                post: (option: { body: Methods_t11qph['post']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_t11qph['post']['resBody'], BasicHeaders, Methods_t11qph['post']['status']>(prefix, `${prefix2}${PATH11}`, POST, option).json(),
                /**
                 * term作成API
                 */
                $post: (option: { body: Methods_t11qph['post']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_t11qph['post']['resBody'], BasicHeaders, Methods_t11qph['post']['status']>(prefix, `${prefix2}${PATH11}`, POST, option).json().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_t11qph['get']['query'] } | undefined) =>
                  `${prefix}${prefix2}${PATH11}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              },
            },
            cards: {
              _card_id: (val4: string) => {
                const prefix4 = `${prefix2}${PATH12}/${val4}`;

                return {
                  claps: {
                    /**
                     * 拍手するendpoint
                     * @returns Created
                     */
                    post: (option: { body: Methods_110f06c['post']['reqBody'], config?: T | undefined }) =>
                      fetch<Methods_110f06c['post']['resBody'], BasicHeaders, Methods_110f06c['post']['status']>(prefix, `${prefix4}${PATH13}`, POST, option).json(),
                    /**
                     * 拍手するendpoint
                     * @returns Created
                     */
                    $post: (option: { body: Methods_110f06c['post']['reqBody'], config?: T | undefined }) =>
                      fetch<Methods_110f06c['post']['resBody'], BasicHeaders, Methods_110f06c['post']['status']>(prefix, `${prefix4}${PATH13}`, POST, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH13}`,
                  },
                  /**
                   * 個別投稿のメッセージ取得API
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_h6q8fw['get']['resBody'], BasicHeaders, Methods_h6q8fw['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * 個別投稿のメッセージ取得API
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_h6q8fw['get']['resBody'], BasicHeaders, Methods_h6q8fw['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * 個別投稿のメッセージ取得API
                   */
                  patch: (option: { body: Methods_h6q8fw['patch']['reqBody'], config?: T | undefined }) =>
                    fetch<Methods_h6q8fw['patch']['resBody'], BasicHeaders, Methods_h6q8fw['patch']['status']>(prefix, prefix4, PATCH, option).json(),
                  /**
                   * 個別投稿のメッセージ取得API
                   */
                  $patch: (option: { body: Methods_h6q8fw['patch']['reqBody'], config?: T | undefined }) =>
                    fetch<Methods_h6q8fw['patch']['resBody'], BasicHeaders, Methods_h6q8fw['patch']['status']>(prefix, prefix4, PATCH, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`,
                };
              },
              many: {
                /**
                 * カード一括追加API
                 */
                post: (option: { body: Methods_4o42tl['post']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_4o42tl['post']['resBody'], BasicHeaders, Methods_4o42tl['post']['status']>(prefix, `${prefix2}${PATH14}`, POST, option).json(),
                /**
                 * カード一括追加API
                 */
                $post: (option: { body: Methods_4o42tl['post']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_4o42tl['post']['resBody'], BasicHeaders, Methods_4o42tl['post']['status']>(prefix, `${prefix2}${PATH14}`, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix2}${PATH14}`,
              },
              tagged: {
                /**
                 * ハッシュタグ個別の投稿情報取得API
                 */
                get: (option: { query: Methods_1qrfwfm['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_1qrfwfm['get']['resBody'], BasicHeaders, Methods_1qrfwfm['get']['status']>(prefix, `${prefix2}${PATH15}`, GET, option).json(),
                /**
                 * ハッシュタグ個別の投稿情報取得API
                 */
                $get: (option: { query: Methods_1qrfwfm['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_1qrfwfm['get']['resBody'], BasicHeaders, Methods_1qrfwfm['get']['status']>(prefix, `${prefix2}${PATH15}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_1qrfwfm['get']['query'] } | undefined) =>
                  `${prefix}${prefix2}${PATH15}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              },
              /**
               * 従業員が受け取ったカードの情報取得API
               * @returns OK
               */
              get: (option: { query: Methods_jgvknx['get']['query'], config?: T | undefined }) =>
                fetch<Methods_jgvknx['get']['resBody'], BasicHeaders, Methods_jgvknx['get']['status']>(prefix, `${prefix2}${PATH12}`, GET, option).json(),
              /**
               * 従業員が受け取ったカードの情報取得API
               * @returns OK
               */
              $get: (option: { query: Methods_jgvknx['get']['query'], config?: T | undefined }) =>
                fetch<Methods_jgvknx['get']['resBody'], BasicHeaders, Methods_jgvknx['get']['status']>(prefix, `${prefix2}${PATH12}`, GET, option).json().then(r => r.body),
              /**
               * 投稿作成 API
               * @returns Created
               */
              post: (option: { body: Methods_jgvknx['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_jgvknx['post']['resBody'], BasicHeaders, Methods_jgvknx['post']['status']>(prefix, `${prefix2}${PATH12}`, POST, option).json(),
              /**
               * 投稿作成 API
               * @returns Created
               */
              $post: (option: { body: Methods_jgvknx['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_jgvknx['post']['resBody'], BasicHeaders, Methods_jgvknx['post']['status']>(prefix, `${prefix2}${PATH12}`, POST, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_jgvknx['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH12}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            },
            employees: {
              _employee_id: (val4: string) => {
                const prefix4 = `${prefix2}${PATH16}/${val4}`;

                return {
                  cards: {
                    clapped: {
                      /**
                       * 従業員が拍手したカードの情報取得API
                       * @returns OK
                       */
                      get: (option: { query: Methods_f6rjq0['get']['query'], config?: T | undefined }) =>
                        fetch<Methods_f6rjq0['get']['resBody'], BasicHeaders, Methods_f6rjq0['get']['status']>(prefix, `${prefix4}${PATH17}`, GET, option).json(),
                      /**
                       * 従業員が拍手したカードの情報取得API
                       * @returns OK
                       */
                      $get: (option: { query: Methods_f6rjq0['get']['query'], config?: T | undefined }) =>
                        fetch<Methods_f6rjq0['get']['resBody'], BasicHeaders, Methods_f6rjq0['get']['status']>(prefix, `${prefix4}${PATH17}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get' | undefined; query: Methods_f6rjq0['get']['query'] } | undefined) =>
                        `${prefix}${prefix4}${PATH17}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
                    },
                    received: {
                      /**
                       * 従業員が受け取ったカードの情報取得API
                       * @returns OK
                       */
                      get: (option: { query: Methods_2tcmvu['get']['query'], config?: T | undefined }) =>
                        fetch<Methods_2tcmvu['get']['resBody'], BasicHeaders, Methods_2tcmvu['get']['status']>(prefix, `${prefix4}${PATH18}`, GET, option).json(),
                      /**
                       * 従業員が受け取ったカードの情報取得API
                       * @returns OK
                       */
                      $get: (option: { query: Methods_2tcmvu['get']['query'], config?: T | undefined }) =>
                        fetch<Methods_2tcmvu['get']['resBody'], BasicHeaders, Methods_2tcmvu['get']['status']>(prefix, `${prefix4}${PATH18}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get' | undefined; query: Methods_2tcmvu['get']['query'] } | undefined) =>
                        `${prefix}${prefix4}${PATH18}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
                    },
                    sent: {
                      /**
                       * 従業員が送ったカードの情報取得API
                       * @returns OK
                       */
                      get: (option: { query: Methods_16fgjxj['get']['query'], config?: T | undefined }) =>
                        fetch<Methods_16fgjxj['get']['resBody'], BasicHeaders, Methods_16fgjxj['get']['status']>(prefix, `${prefix4}${PATH19}`, GET, option).json(),
                      /**
                       * 従業員が送ったカードの情報取得API
                       * @returns OK
                       */
                      $get: (option: { query: Methods_16fgjxj['get']['query'], config?: T | undefined }) =>
                        fetch<Methods_16fgjxj['get']['resBody'], BasicHeaders, Methods_16fgjxj['get']['status']>(prefix, `${prefix4}${PATH19}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get' | undefined; query: Methods_16fgjxj['get']['query'] } | undefined) =>
                        `${prefix}${prefix4}${PATH19}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
                    },
                  },
                  slack_notifications_setting: {
                    /**
                     * slack通知
                     * @returns ok
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<Methods_10e8p1n['get']['resBody'], BasicHeaders, Methods_10e8p1n['get']['status']>(prefix, `${prefix4}${PATH20}`, GET, option).json(),
                    /**
                     * slack通知
                     * @returns ok
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<Methods_10e8p1n['get']['resBody'], BasicHeaders, Methods_10e8p1n['get']['status']>(prefix, `${prefix4}${PATH20}`, GET, option).json().then(r => r.body),
                    /**
                     * 従業員用slack通知設定更新api
                     * @returns ok
                     */
                    patch: (option: { body: Methods_10e8p1n['patch']['reqBody'], config?: T | undefined }) =>
                      fetch<Methods_10e8p1n['patch']['resBody'], BasicHeaders, Methods_10e8p1n['patch']['status']>(prefix, `${prefix4}${PATH20}`, PATCH, option).json(),
                    /**
                     * 従業員用slack通知設定更新api
                     * @returns ok
                     */
                    $patch: (option: { body: Methods_10e8p1n['patch']['reqBody'], config?: T | undefined }) =>
                      fetch<Methods_10e8p1n['patch']['resBody'], BasicHeaders, Methods_10e8p1n['patch']['status']>(prefix, `${prefix4}${PATH20}`, PATCH, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH20}`,
                  },
                  /**
                   * 個別従業員情報API
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_1a7zqsk['get']['resBody'], BasicHeaders, Methods_1a7zqsk['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * 個別従業員情報API
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_1a7zqsk['get']['resBody'], BasicHeaders, Methods_1a7zqsk['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * 従業員情報更新API
                   */
                  patch: (option: { body: Methods_1a7zqsk['patch']['reqBody'], config?: T | undefined }) =>
                    fetch<Methods_1a7zqsk['patch']['resBody'], BasicHeaders, Methods_1a7zqsk['patch']['status']>(prefix, prefix4, PATCH, option, 'FormData').json(),
                  /**
                   * 従業員情報更新API
                   */
                  $patch: (option: { body: Methods_1a7zqsk['patch']['reqBody'], config?: T | undefined }) =>
                    fetch<Methods_1a7zqsk['patch']['resBody'], BasicHeaders, Methods_1a7zqsk['patch']['status']>(prefix, prefix4, PATCH, option, 'FormData').json().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`,
                };
              },
              /**
               * webカード投稿画面で従業員一覧を取得するエンドポイント
               * @returns 成功時の値
               */
              get: (option?: { query?: Methods_z4epn['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_z4epn['get']['resBody'], BasicHeaders, Methods_z4epn['get']['status']>(prefix, `${prefix2}${PATH16}`, GET, option).json(),
              /**
               * webカード投稿画面で従業員一覧を取得するエンドポイント
               * @returns 成功時の値
               */
              $get: (option?: { query?: Methods_z4epn['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_z4epn['get']['resBody'], BasicHeaders, Methods_z4epn['get']['status']>(prefix, `${prefix2}${PATH16}`, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_z4epn['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH16}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            },
            team_hash_tags: {
              /**
               * チームハッシュタグ一覧取得API
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_141urs0['get']['resBody'], BasicHeaders, Methods_141urs0['get']['status']>(prefix, `${prefix2}${PATH21}`, GET, option).json(),
              /**
               * チームハッシュタグ一覧取得API
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_141urs0['get']['resBody'], BasicHeaders, Methods_141urs0['get']['status']>(prefix, `${prefix2}${PATH21}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH21}`,
            },
          };
        },
      },
    },
  };
};

export type ApiInstance = ReturnType<typeof api>;
export default api;
