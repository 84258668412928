import useAspidaSWR from '@aspida/swr';
import { aspidaClient } from '@lib/aspida';

export function useEmployeeForAdmin(tenantId?: string, employeeId?: string) {
  return useAspidaSWR(
    aspidaClient.web.tenants
      ._tenant_id(tenantId ?? '')
      .admin.employees._employee_id(employeeId ?? ''),
    {
      enabled: !!tenantId && !!employeeId,
    }
  );
}
