import useAspidaSWR from '@aspida/swr';
import { aspidaClient } from '@lib/aspida';

export function useTenantForAdmin(tenantId?: string) {
  return useAspidaSWR(
    aspidaClient.web.tenants._tenant_id(tenantId ?? '').admin.tenant,
    {
      enabled: !!tenantId,
    }
  );
}
