import { useCallback } from 'react';
import { api } from '@lib/api';
import { rollbar } from '@lib/rollbar';
// import { useRouter } from 'next/router';

/**
 * ログアウトを実行する logout 関数を返す
 * ログアウトが成功すると /login にリダイレクトする
 * @todo /login を tip_frontend に移行するタイミングで useRouter を使った実装に切り替える
 */
export const useLogout = () => {
  // const router = useRouter();

  const logout = useCallback(() => {
    api
      .logout()
      .then(() => (window.location.href = '/login'))
      .catch((error) => rollbar.error(error));
  }, []);

  return logout;
};
