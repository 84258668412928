import { ReactElement } from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import { ReactNotifications } from 'react-notifications-component';
import { theme } from '../styles/theme';
import { GlobalStyles } from '../styles/globalStyles';
import { useRequireLogin } from '../hooks';
import { useRequireAdmin } from 'src/hooks/useRequireAdmin';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';

function MyApp({ Component, pageProps }: AppProps): ReactElement {
  useRequireLogin();
  useRequireAdmin();

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <title>エモチップ</title>
      </Head>
      <GlobalStyles />
      <ReactNotifications />
      <Component {...pageProps} />
    </ThemeProvider>
  );
}

export default MyApp;
