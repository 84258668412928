import Rollbar from 'rollbar';

const rollbarToken =
  typeof window === 'undefined'
    ? process.env.ROLLBAR_SERVER_ACCESS_TOKEN
    : process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_ACCESS_TOKEN;

export const rollbar = new Rollbar({
  /**
   * token が設定されていないか空文字のときは無効化する
   */
  enabled: !!rollbarToken,
  accessToken: rollbarToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  scrubTelemetryInputs: true,
  payload: {
    environment: process.env.NEXT_PUBLIC_ROLLBAR_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.NEXT_BUILD_ID,
        guess_uncaught_frames: true,
      },
    },
  },
});
