import 'styled-components';

export const theme = {
  colors: {
    base: {
      text: {
        main: '#474747',
        sub: '#878787',
      },
      border: {
        main: '#d8d8df',
      },
      bg: {
        main: '#ffffff',
        body: '#f4f4f6',
        modal: 'rgba(255, 255, 255, 0.8)',
        iconLabel: 'rgba(71, 71, 71, 0.4)',
      },
    },
    primary: {
      light: '#ff7326',
      main: '#ff630d',
      dark: '#f25600',
    },
    error: {
      light: '#ffeded',
      main: '#e01e1e',
    },
  },
  shadow: {
    base: {
      main: '0 4px 14px rgba(71, 71, 71, 0.4)',
    },
    primary: {
      main: '0 4px 14px rgba(255, 99, 13, 0.4)',
      hover: '0 4px 14px rgba(255, 99, 13, 0.6)',
    },
  },
  blur: {
    modal: 'blur(4px)',
  },
  height: {
    header: '80px',
  },
  breakpoints: {
    xsmall: '400px',
    small: '576px',
    medium: '768px',
    large: '992px',
    xlarge: '1200px',
  },
  zIndex: {
    list: 9,
    header: 10,
    userMenuCover: 11,
    userMenu: 12,
    modalCover: 13,
    modal: 14,
    loadingCover: 15,
    loading: 16,
  },
} as const;

type AppTheme = typeof theme;

declare module 'styled-components' {
  /** ref: https://github.com/DefinitelyTyped/DefinitelyTyped/blob/9209cf019bfe075b5069a515742d4e1695a41d51/types/styled-components/index.d.ts#L353-L359 */
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends AppTheme {}
}
