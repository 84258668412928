import useAspidaSWR from '@aspida/swr';
import { aspidaClient } from '@lib/aspida';

/**
 * ログイン中の従業員情報を返す
 */
export function useCurrentEmployee(enabled = true) {
  return useAspidaSWR(aspidaClient.web.me, { enabled: enabled });
}

/**
 * ログイン中の従業員のポイント情報を返す
 */
export function useCurrentEmployeeTips(enabled = true) {
  return useAspidaSWR(aspidaClient.web.me.points, { enabled: enabled });
}
