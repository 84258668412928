import useSWRInfinite from 'swr/infinite';
import { api } from '@lib/api';
import { KeyLoader } from 'swr';
import { WebCardsSchema } from 'src/__generated__/@types';
import { useCallback } from 'react';

export type UseCardsOptions = {
  type: 'all' | 'received' | 'sent' | 'clapped';
  tenantId?: number;
  employeeId?: number;
};

export function useCards({ type, tenantId, employeeId }: UseCardsOptions) {
  const fetcher = (() => {
    if (!tenantId || !employeeId) {
      return null;
    }

    if (type === 'all') {
      return (_key: string, pageIndex: number) => {
        return api.getAllCards(pageIndex, tenantId);
      };
    } else if (type === 'received') {
      return (_key: string, pageIndex: number) => {
        return api.getReceivedCardsPerEmployee(pageIndex, tenantId, employeeId);
      };
    } else if (type === 'sent') {
      return (_key: string, pageIndex: number) => {
        return api.getSentCardsPerEmployee(pageIndex, tenantId, employeeId);
      };
    } else if (type === 'clapped') {
      return (_key: string, pageIndex: number) => {
        return api.getClappedCardsPerEmployee(pageIndex, tenantId, employeeId);
      };
    } else {
      return null;
    }
  })();

  const getKey: KeyLoader = (
    index,
    previousData: {
      cards: WebCardsSchema;
      has_next: boolean;
    }
  ) => {
    if (
      !tenantId ||
      fetcher === null ||
      (previousData && !previousData.has_next)
    ) {
      return null;
    }
    /**
     * page index のみを SWR の key にしてしまうと他の種類の API リクエストと重複してしまう。
     * 基本的に利用しないが、 tenantId + type で一意な key を定義して受け取る。
     */
    const keyForFetching = tenantId + type;
    return [keyForFetching, index + 1];
  };

  const {
    data: fetchedCardsData,
    error,
    mutate: cardsMutate,
    size,
    setSize,
  } = useSWRInfinite(getKey, fetcher);

  const handleClickLoadButton = useCallback(() => {
    setSize(size + 1);
  }, [size, setSize]);

  const isLoadingInitialData = !fetchedCardsData && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 &&
      !!fetchedCardsData &&
      typeof fetchedCardsData[size - 1] === 'undefined');
  const isReachingEnd =
    !!fetchedCardsData &&
    !fetchedCardsData[fetchedCardsData.length - 1].has_next;

  return {
    fetchedCardsData,
    cardsMutate,
    handleClickLoadButton,
    isLoadingMore,
    isReachingEnd,
  };
}
