import useAspidaSWR from '@aspida/swr';
import { aspidaClient } from '@lib/aspida';

export function useTeamHashTagForAdmin(
  tenantId?: string,
  teamHashTagId?: string
) {
  return useAspidaSWR(
    aspidaClient.web.tenants
      ._tenant_id(tenantId ?? '')
      .admin.team_hash_tags._team_hash_tag_id(teamHashTagId ?? ''),
    {
      enabled: !!tenantId && !!teamHashTagId,
    }
  );
}
