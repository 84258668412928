import { aspidaClient } from '@lib/aspida';
import type { KeyLoader } from 'swr';
import type { WebEmployeeSchema } from 'src/__generated__/@types';
import useSWRInfinite from 'swr/infinite';
import { useEffect } from 'react';

export function useAllEmployees(tenantId?: number) {
  const fetcher = (() => {
    if (!tenantId) {
      return null;
    }

    return (_key: string, pageIndex: number) => {
      return aspidaClient.web.tenants
        ._tenant_id(String(tenantId) ?? '')
        .employees.$get({ query: { page: pageIndex } });
    };
  })();

  const getKey: KeyLoader = (
    index,
    previousData: {
      employees: WebEmployeeSchema;
      has_next: boolean;
    }
  ) => {
    if (
      !tenantId ||
      fetcher === null ||
      (previousData && !previousData.has_next)
    ) {
      return null;
    }
    return [tenantId, index + 1];
  };

  const {
    data: fetchedEmployeesData,
    error,
    mutate: employeesMutate,
    size,
    setSize,
  } = useSWRInfinite(getKey, fetcher, {
    revalidateAll: false,
    revalidateFirstPage: false,
  });

  const isLoadingInitialData = !fetchedEmployeesData && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 &&
      !!fetchedEmployeesData &&
      typeof fetchedEmployeesData[size - 1] === 'undefined');
  const isReachingEnd =
    !!fetchedEmployeesData &&
    !fetchedEmployeesData[fetchedEmployeesData.length - 1].has_next;

  useEffect(() => {
    if (!isLoadingMore && !isReachingEnd) {
      setSize(size + 1);
    }
  }, [isLoadingMore, isReachingEnd, setSize, size]);

  return {
    fetchedEmployeesData,
    employeesMutate,
    isLoadingMore,
    isReachingEnd,
  };
}
