import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useCurrentEmployee } from './useCurrentEmployee';

const noLoginRequiredPaths: string[] = [
  '/login',
  '/auth/azureactivedirectory/callback',
];

/**
 * セッションが切れている場合はログイン画面に遷移する。
 * ログイン画面ではリダイレクトは行わない。
 */
export function useRequireLogin() {
  const router = useRouter();
  const isRequiredPath = !noLoginRequiredPaths.includes(router.pathname);
  const { data, error } = useCurrentEmployee(isRequiredPath);

  useEffect(() => {
    if (!isRequiredPath) return;

    if (!data && error) {
      router.push('/login');
    }
  }, [data, error, router, isRequiredPath]);
}
