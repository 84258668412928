import type { Employee } from '@type/Employee';
import { Tenant } from '@type/tenant_setting';
import { Term } from '@type/tenant_setting';
import { TeamHashTag } from '@type/tenant_setting';
import {
  WebTermPostOrPatchSchema,
  WebUpdateEmployeeBodyForAdminSchema,
  WebUpdateEmployeeBodySchema,
  WebTenantForAdminSchema,
  WebSlackNotificationEmployee,
  WebCardMessageSchema,
  WebAdminUpdateTeamHashTagBodySchema,
  WebCardSchema,
  WebCardPostSchema,
  WebCardsPostSchema,
} from 'src/__generated__/@types';
import { aspidaClient } from './aspida';

export const api = {
  login: async (code: string, code_verify?: string) => {
    return aspidaClient.web.auth.azure_sessions.post({
      body: { code: code, code_verify: code_verify },
    });
  },
  logout: async () => {
    return aspidaClient.web.logout.delete();
  },
  updateEmployee: async (
    employee: Employee,
    body: WebUpdateEmployeeBodySchema
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(employee.tenant_id))
      .employees._employee_id(String(employee.id))
      .patch({ body });
  },
  getTerms: async (tenant_id: Employee['tenant_id'], pageIndex: number) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .admin.terms.$get({ query: { page: pageIndex } });
  },
  createTeamHashTag: async (tenant_id: Employee['tenant_id'], name: string) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .admin.team_hash_tags.$post({ body: { name: name } });
  },
  deleteTeamHashTag: async (
    tenant_id: Employee['tenant_id'],
    team_hash_tag_id: TeamHashTag['id']
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .admin.team_hash_tags._team_hash_tag_id(String(team_hash_tag_id))
      .$delete();
  },
  createTerm: async (
    tenant_id: Employee['tenant_id'],
    body: WebTermPostOrPatchSchema
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .admin.terms.post({ body });
  },
  getEmployeesForAdmin: async (
    tenant_id: Employee['tenant_id'],
    query: Parameters<
      ReturnType<
        typeof aspidaClient.web.tenants._tenant_id
      >['admin']['employees']['$get']
    >[0]['query']
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .admin.employees.$get({ query });
  },
  updateTenantForAdmin: async (
    tenant: Tenant['id'],
    body: WebTenantForAdminSchema
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant))
      .admin.tenant.$patch({ body });
  },
  updateEmployeeForAdmin: async (
    tenantId: Employee['tenant_id'],
    employeeId: Employee['id'],
    body: WebUpdateEmployeeBodyForAdminSchema
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenantId))
      .admin.employees._employee_id(String(employeeId))
      .patch({ body });
  },
  getEmployeesCsvForAdmin: async (
    tenant_id: Employee['tenant_id'],
    query: Parameters<
      ReturnType<
        typeof aspidaClient['web']['tenants']['_tenant_id']
      >['admin']['csv']['employees']['$get']
    >[0]['query']
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .admin.csv.employees.$get({ query: query });
  },
  getEmployeesCsvForAdminWithPaging: async (
    tenant_id: Employee['tenant_id'],
    query: Parameters<
      ReturnType<
        typeof aspidaClient['web']['tenants']['_tenant_id']
      >['admin']['csv']['paging']['employees']['$get']
    >[0]['query']
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .admin.csv.paging.employees.$get({ query: query });
  },
  getCardsCsvForAdmin: async (
    tenant_id: Employee['tenant_id'],
    query: {
      started_at: string;
      ended_at?: string | undefined;
    }
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .admin.csv.cards.$get({ query: query });
  },
  updateTerm: async (
    tenant_id: Employee['tenant_id'],
    term_id: Term['id'],
    body: WebTermPostOrPatchSchema
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .admin.terms._term_id(String(term_id))
      .patch({ body });
  },
  updateSlackNotificationSetting: async (
    employee: Employee,
    body: { slack_notifications_setting: WebSlackNotificationEmployee }
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(employee.tenant_id))
      .employees._employee_id(String(employee.id))
      .slack_notifications_setting.$patch({ body });
  },
  updateCardMessage: async (
    tenant_id: Employee['tenant_id'],
    body: { card: WebCardMessageSchema }
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .cards._card_id(String(body.card.id))
      .patch({ body });
  },
  updateTeamHashTag: async (
    tenant_id: Employee['tenant_id'],
    team_hash_tag_id: TeamHashTag['id'],
    body: WebAdminUpdateTeamHashTagBodySchema
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .admin.team_hash_tags._team_hash_tag_id(String(team_hash_tag_id))
      .patch({ body });
  },
  getReceivedCardsPerEmployee: async (
    pageIndex: number,
    tenant_id: Employee['tenant_id'],
    employee_id: Employee['id']
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .employees._employee_id(String(employee_id))
      .cards.received.$get({ query: { page: pageIndex } });
  },
  getSentCardsPerEmployee: async (
    pageIndex: number,
    tenant_id: Employee['tenant_id'],
    employee_id: Employee['id']
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .employees._employee_id(String(employee_id))
      .cards.sent.$get({ query: { page: pageIndex } });
  },
  getClappedCardsPerEmployee: async (
    pageIndex: number,
    tenant_id: Employee['tenant_id'],
    employee_id: Employee['id']
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .employees._employee_id(String(employee_id))
      .cards.clapped.$get({ query: { page: pageIndex } });
  },
  clap: async (
    tenant_id: Employee['tenant_id'],
    card_id: WebCardSchema['id']
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .cards._card_id(String(card_id))
      .claps.$post({
        body: {
          clap: {
            // Web では今の所拍手ボタン1リクエストにつき必ず1回なので固定値で指定している。
            amount: 1,
          },
        },
      });
  },
  getTaggedCards: async (
    tag: string,
    pageIndex: number,
    tenant_id: Employee['tenant_id']
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .cards.tagged.$get({ query: { tag: tag, page: pageIndex } });
  },
  getAllCards: async (pageIndex: number, tenant_id: Employee['tenant_id']) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .cards.$get({ query: { page: pageIndex } });
  },
  createCard: async (
    tenant_id: Employee['tenant_id'],
    body: WebCardPostSchema
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .cards.post({ body });
  },
  createCards: async (
    tenant_id: Employee['tenant_id'],
    body: WebCardsPostSchema
  ) => {
    return aspidaClient.web.tenants
      ._tenant_id(String(tenant_id))
      .cards.many.post({ body });
  },
};
