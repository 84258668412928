import { createGlobalStyle } from 'styled-components';
import { resetStyles } from './resetStyles';

export const GlobalStyles = createGlobalStyle`
  ${resetStyles}

  body {
    font-family: 'Lato', -apple-system, 'BlinkMacSystemFont', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif, 'Segoe UI Emoji';
    font-size: 16px;
    background-color: ${({ theme }) => theme.colors.base.bg.body};
    color: ${({ theme }) => theme.colors.base.text.main};
    line-height: 1.46668;
  }
`;
