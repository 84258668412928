import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useCurrentEmployee } from './useCurrentEmployee';

/**
 * /tenant_setting で始まる path のときは admin role を必須とする
 * admin ではないときはトップページへリダイレクトする
 */
export function useRequireAdmin() {
  const router = useRouter();
  const requireAdminPathPrefix = '/tenant_setting';
  const isRequiredPath = router.pathname.startsWith(requireAdminPathPrefix);
  const { data: employee } = useCurrentEmployee(isRequiredPath);

  useEffect(() => {
    if (!isRequiredPath) return;

    if (employee && employee.role !== 'admin') {
      /**
       * @todo / を tip_frontend に移行する際は next/router を使って書き換える
       */
      window.location.href = '/';
    }
  }, [employee, isRequiredPath]);
}
